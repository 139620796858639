import React, { useContext } from "react";
import { UserContext } from './UserContext';
import { Link } from 'react-router-dom';

export default function Panel(){

    // --- Użytkownik i wylogowanie ---

    const {logout} = useContext(UserContext);

    return (
        <div id="app-outer-container">
            <div id="admin-menu-wrapper">
                <Link to="main"><button className="admin-choice-button">Aplikacja</button></Link>
                <Link to="admin"><button className="admin-choice-button">Panel administracyjny</button></Link>
                <button className="admin-choice-button" onClick={logout}>Wyloguj się</button>
            </div>
        </div>
    );

};  