import React, { useState, useEffect, useContext } from "react";
import { UserContext, Axios } from './UserContext';
import { db } from "./Db";
import { Link } from 'react-router-dom';
import {ReactComponent as Gear} from '../images/gear.svg';
import Icon1 from '../images/icon1.png';
import {ReactComponent as Icon2} from '../images/icon2.svg';
import {ReactComponent as Icon3} from '../images/icon3.svg';
import {ReactComponent as Icon4} from '../images/icon4.svg';
import {ReactComponent as Icon5} from '../images/icon5.svg';
import {ReactComponent as Icon6} from '../images/icon6.svg';
import {ReactComponent as Icon7} from '../images/icon7.svg';
import {ReactComponent as Icon8} from '../images/icon8.svg';
import {ReactComponent as Icon9} from '../images/icon9.svg';
import {ReactComponent as Icon10} from '../images/icon10.svg';
import {ReactComponent as Icon11} from '../images/icon11.svg';
import {ReactComponent as Icon12} from '../images/icon12.svg';
import {ReactComponent as Hamburger} from '../images/hamburger.svg';
import TrainFront from '../images/train-front.png';
import TrainCar from '../images/train-car.png';

export default function Main(){

    // --- Użytkownik i wylogowanie ---

    const {user} = useContext(UserContext);

    const {logout} = useContext(UserContext);

    // Przełącznik menu

    const [appLayer, setAppLayer] = useState(100);

    // --- Obieg ankieterski ---

    // Aktywny obieg

    const [active, setActive] = useState({
        cancel: '',
        job_date: '',
        job_number: '',
        train_id: '',
        train_number: '',
        total_cars: '',
        first_car: '',
        last_car: '',
        relation: '',
        start_hour: '',
        end_hour: '',
        status: ''
    });

    // Główne obiekty pobrane bezpośrednio z bazy danych

    // Wszystkie obiegi przyporządkowane do pracownika

    const [jobs, setJobs] = useState([]);

    // Wszystkie kursy przyporządkowane do pracownika

    const [rides, setRides] = useState([]);

    // Wszystkie pociągi przyporządkowane do pracownika

    const [trains, setTrains] = useState([]);

    // Wszystkie stacje przyporządkowane do pracownika

    const [stations, setStations] = useState([]);

    // Wszystkie numery pociągów przyporządkowane do pociągu

    const [trainNumbers, setTrainNumbers] = useState([]);

    // ----------------------------------------------------

    // Obiegi przyporządkowane do wybranej wcześniej daty

    const [separatedJobs, setSeparatedJobs] = useState([]);

    // Nazwy obiegów

    const [jobsNames, setJobsNames] = useState([]);

    // Daty obiegów

    const [jobsDates, setJobsDates] = useState([]);

    // Wybrany obieg

    const [activeJob, setActiveJob] = useState([]);

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);

    // Aktywne kursy przyporządkowane do wybranego obiegu

    const [activeRides, setActiveRides] = useState([]);

    // Id pociągów w aktywnych kursach

    const [activeTrainIds, setActiveTrainIds] = useState([]);

    // Aktywne pociągi na podstawie obiegu

    const [activeTrains, setActiveTrains] = useState([]);

    // Obiekt zawierający listę stacji do pomiaru

    const [measurementStations, setMeasurementStations] = useState([]);

    // Obiekt zawierający wszystkie pomiary

    const [measurementFormData, setMeasurementFormData] = useState({});

    // Obiekt zawierający informacje o skończonych etapach

    const [completedMeasurements, setCompletedMeasurements] = useState([]);

    //const today = new Date().toISOString().slice(0, 10);

    // --- Lokalizacja i pozyskiwanie zgody ---

    // Lokalizacja użytkownika

    const [userCoordinates, setUserCooridinates] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    // Stan zgody na lokalizację

    const [permissionsState, setPermissionsState] = useState(
        {
            location: ""
        }
    );

    // Pozyskiwanie aktualnej lokalizacji

    function getLocation(){
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserCooridinates({lat: position.coords.latitude, lng: position.coords.longitude});
            },
            (error) => console.warn(error.message),
            { maximumAge:20000, timeout:10000, enableHighAccuracy: true }
        );
    }

    useEffect(() => {
        const h = setInterval(() => {
            getLocation();
        }, 7500)
        return () => { clearInterval(h) }
    }, []);

    // Sprawdzanie zgody na lokalizację

    useEffect(() => {
        getLocation();
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            setPermissionsState({location:result.state});
            result.addEventListener("change", () => {
                setPermissionsState({location:result.state});
            });
        });
    }, []);

    // --- Uzyskiwanie danych ---

    // Pobieranie danych

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData(par) {

        let personal_id;

        if(!par){
            personal_id = user.personal_id;
        } else {
            personal_id = '*';
        }

        setSearching(true);

        Axios.post('classes/data.php', { personal_id }, { timeout: 5000 }).then(function(response){
            if(response.data){
                if(Array.isArray(response.data.jobs)){
                    setJobs(response.data.jobs);
                    setJobsOffline(response.data.jobs);
                }
                if(Array.isArray(response.data.rides)){
                    setRides(response.data.rides);
                    setRidesOffline(response.data.rides);
                }
                if(Array.isArray(response.data.trains)){
                    setTrains(response.data.trains);
                    setTrainsOffline(response.data.trains);
                }
                if(Array.isArray(response.data.stations)){
                    setStations(response.data.stations);
                    setStationsOffline(response.data.stations);
                }
                if(Array.isArray(response.data.train_numbers)){
                    setTrainNumbers(response.data.train_numbers);
                    setTrainNumbersOffline(response.data.train_numbers);
                }
                setSearching(false);
            } else if (response.data === 0) {
                setJobs([]);
                setJobsOffline([]);
                setRides([]);
                setRidesOffline([]);
                setTrains([]);
                setTrainsOffline([]);
                setStations([]);
                setStationsOffline([]);
                setTrainNumbers([]);
                setTrainNumbersOffline([]);
                setSearching(false);
            } else {
                checkDataOffline();
            }
        }).catch((error) => {
            console.warn(error);
            checkDataOffline();
        });

    }

    // Sprawdzenie lokalnej bazy danych w przypadku braku połączenia z zewnętrzną bazą

    function checkDataOffline(){
        db.jobs.toArray().then(function(result){
            if(result.length > 0){
                setJobs(result);
            }
            setSearching(false); 
        });
        db.rides.toArray().then(function(result){
            if(result.length > 0){
                setRides(result);
            }
        });
        db.trains.toArray().then(function(result){
            if(result.length > 0){
                setTrains(result);
            }
        });
        db.stations.toArray().then(function(result){
            if(result.length > 0){
                setStations(result);
            }
        });
        db.train_numbers.toArray().then(function(result){
            if(result.length > 0){
                setTrainNumbers(result);
            }
        });
    }

    function setJobsOffline(data){
        db.jobs.clear();
        db.jobs.bulkPut(data)
    }

    function setRidesOffline(data){
        db.rides.clear();
        db.rides.bulkPut(data)
    }

    function setTrainsOffline(data){
        db.trains.clear();
        db.trains.bulkPut(data)
    }

    function setStationsOffline(data){
        db.stations.clear();
        db.stations.bulkPut(data)
    }

    function setTrainNumbersOffline(data){
        db.train_numbers.clear();
        db.train_numbers.bulkPut(data)
    }

    // Wyodrębnianie dat obiegów

    useEffect(() => {
        if(jobs){
            setJobsDates([...new Set(jobs.map(item => item.job_date))]);
        }
    }, [jobs]);

    // Tworzenie listy unikalnych numerów obiegu na podstawie wybranej daty

    useEffect(() => {
        if(active.job_date){
            setSeparatedJobs(jobs.filter(obj => {
                return obj.job_date === active.job_date
            }));
        }
    }, [active.job_date, jobs]);

    // Wyodrębnianie nazw obiegów

    useEffect(() => {
        setJobsNames([...new Set(separatedJobs.map(item => item.job_number))]);
    }, [separatedJobs]);

    // Ustawianie aktywnego obiegu(daty i numeru)

    function setActiveSelection(name, value){
        if(name === 'job_number'){
            setActive({...active, job_number: value, train_id: '', train_number: '', total_cars: '', first_car: '', last_car: '', relation: '', start_hour: '', end_hour: '', status: ''});
            db.selected.toArray().then(function(result){
                if(result.length > 0){
                    db.selected.update(1, {'job_date': active.job_date, 'job_number': value});
                } else {
                    db.selected.put({'job_date': active.job_date, 'job_number': value});
                }
            });
            setActiveRides([]);
            setActiveTrainIds([]);
            setActiveTrains([]);
            setMeasurementStations([]);
            setMeasurementFormData({});
            setCompletedMeasurements([]);
        } else {
            setActive({...active, [name]: value});
        }
    }

    // Anulowanie zmiany obiegu

    function cancelJobChange(){
        db.selected.toArray().then(function(result){
            let data = result[0];
            setActive({...active, 'cancel': '', 'job_date': data.job_date, 'job_number': data.job_number});
        });
    }

    // Wyodrębnianie wybranego aktywnego obiegu

    useEffect(() => {
        if(active.job_number){
            setActiveJob(separatedJobs.filter(obj => {
                return obj.job_number === active.job_number
            }));
        }
    }, [active.job_number, separatedJobs]);

    // Wyodrębnianie kursów z aktywnego obiegu

    useEffect(() => {
        if(active.job_number && activeJob.length > 0){
            setActiveRides(rides.filter(obj => {
                return obj.job_number === active.job_number
            }));
        }
    }, [activeJob, rides, active.job_number]);

    // Wyodrębnianie id pociągów z aktywnych kursów i dodawanie danych odnośnie pociągów

    useEffect(() => {

        if(activeRides.length > 0){ 
            setActiveTrainIds([...new Set(activeRides.map(item => item.train_id))]);
            activeRides.forEach(ride => {
                const first_station_name = stations.find(u => u.station_id === ride.first_station_id);
                const last_station_name = stations.find(u => u.station_id === ride.last_station_id);
                const train_number = trainNumbers.find(u => u.train_id === ride.train_id);
                ride.first_station_name = first_station_name.name;
                ride.last_station_name = last_station_name.name;
                ride.train_number = train_number.train_number;
                const begin_station_name = stations.find(u => u.station_id === train_number.first_station);
                const end_station_name = stations.find(u => u.station_id === train_number.last_station);
                ride.begin_station = begin_station_name.name;
                ride.end_station = end_station_name.name;
            });
        }
        
    }, [activeRides, stations, trainNumbers]);

    // Wyodrębnianie aktywnych pociągów

    useEffect(() => {
        if(activeTrainIds.length > 0){
            setActiveTrains(trains.filter(train => activeTrainIds.includes(train.train_id)));
        }
    }, [activeTrainIds, trains]);

    // Dodanie informacji o pociągach

    useEffect(() => {
        if(activeTrains.length > 0){
            activeTrains.forEach(train => {
                const trainRide = activeRides.filter(ride => ride.train_id === train.train_id);
                let startStation = trainRide[0].first_station_id;
                let endStation = trainRide[0].last_station_id;
                const arr = [];
                for (let i = 1; i <= 25; i++) {
                    if(!train['station_'+i]){
                        break;
                    } else {
                        const small = [];
                        window['station_' + i + '_name'] = stations.find(u => u.station_id === train['station_'+i]);
                        train['station_' + i + '_name'] = window['station_' + i + '_name'].name;
                        small.push(train['station_' + i], window['station_' + i + '_name'].name, train['arrival_hour_'+i], train['departure_hour_'+i], train['platform_number_'+i], train['lane_number_'+i]);
                        arr.push(small);
                        if(train['station_'+i] === startStation){
                            train.measurement_start_hour = train['departure_hour_'+i];
                            train.start_station_index = i-1;
                        }
                        if(train['station_'+i] === endStation){
                            train.measurement_end_hour = train['arrival_hour_'+i];
                            train.end_station_index = i;
                        }
                    }
                }
                train.stations = arr;
            });
        }
    }, [activeTrains, stations, activeRides]);

    // Pokazywanie lub ukrywanie rozkładu jazdy pociągu

    function trainDetailsToggle(id){
        let el = document.querySelector("#train-details-inner-section-"+id);
        let button = document.querySelector("#train-details-show-button-"+id);
        if(el.classList.contains('train-is')){
            el.classList.remove('train-is');
            button.innerText = 'Ukryj';
        } else {
            el.classList.add('train-is');
            button.innerText = 'Pokaż';
            button.scrollIntoView();
        }
    }

    // Wybór pociągu do pomiarów

    function activateTrain(id){
        if(id !== active.train_id){
            setMeasurementFormData({});
        }
        const chosenRide = activeRides.filter(ride => ride.train_id === id);
        const chosenTrain = activeTrains.filter(train => train.train_id === id);
        if(chosenRide && chosenTrain){
            setActive({...active, train_id: chosenRide[0].train_id, train_number: chosenRide[0].train_number, total_cars: parseInt(chosenRide[0].total_cars), first_car: parseInt(chosenRide[0].first_car), last_car: parseInt(chosenRide[0].last_car), relation: chosenRide[0].begin_station + ' - ' + chosenRide[0].end_station, start_hour: chosenTrain[0].measurement_start_hour, end_hour: chosenTrain[0].measurement_end_hour, status: chosenRide[0].status});
            setActiveLayer(100);
        }
    }

    useEffect(() => {

        if(active.train_id){

            setCompletedMeasurements([]);

            const chosenTrain = activeTrains.filter(train => train.train_id === active.train_id);

            let first = chosenTrain[0].start_station_index;
            let last = chosenTrain[0].end_station_index;

            const stations = chosenTrain[0].stations.slice(first, last);

            const arr = [];

            for(let i=0; i<stations.length; i++){
                if(i === 0){
                    arr.push(stations[i]);
                }
                if(i > 0){
                    const small = [];
                    small[0] = stations[i-1][0] + '-' + stations[i][0];
                    small[1] = stations[i-1][1] + ' - ' + stations[i][1];
                    small[2] = stations[i-1][3] + ' - ' + stations[i][2];
                    arr.push(small);
                    arr.push(stations[i]);
                }
            }

            setMeasurementStations(arr);

            checkMeasurementsDB();
            
        }

        function checkMeasurementsDB(){         

            db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id}).toArray().then(function(result){
                if(result.length > 0){
                    setMeasurementsFromDB(result);
                }
            });

        }
        
    }, [active.train_id, activeTrains, active.job_date, active.job_number]);

    // Wypełnianie pomiarów na podstawie zapisanych w lokalnej bazie danych

    const [measurementsFromDB, setMeasurementsFromDB] = useState([]);

    const [latestMeasurements, setLatestMeasurements] = useState([]);

    useEffect(() => {

        if(measurementsFromDB.length > 0){
              
            const result = measurementsFromDB.reduce((acc, obj) => {
                const { station_index, measurement_id } = obj;
              
                if (!acc[station_index] || acc[station_index].measurement_id < measurement_id) {
                  acc[station_index] = obj;
                }
              
                return acc;
            }, {});
              
            const finalArray = Object.values(result);

            let lastMeasurement = Math.max(...finalArray.map(a => a.station_index));

            const completedArray = [];

            for(let i = 0; i <= lastMeasurement; i++){
                completedArray.push(i);
            }

            setCompletedMeasurements(completedArray);
              
            setLatestMeasurements(finalArray);

        }
        
    }, [measurementsFromDB]);

    useEffect(() => {

        const result = latestMeasurements.map(a => a.measurements);

        const storage = [];

        result.forEach(item => {

            if(Object.keys(item).some(function(k){ return ~k.indexOf("user_camera_photo") })){

                Object.entries(item).forEach(([key, value], ix) => {
    
                    if(key.includes("user_camera_photo")){
    
                        const arr = key.split("-");
                        let index = arr[1];
    
                        const objectUrl = URL.createObjectURL(value);
    
                        const obj = {["user_camera_photo_url-"+index]: objectUrl}
    
                        storage.push(obj);
    
                        return () => URL.revokeObjectURL(objectUrl);
    
                    }
    
                });
    
            }

        });

        if(storage.length > 0){
            storage.forEach(item => {
                result.push(item);
            })
        }

        const allMeasurements = Object.assign({}, ...result);

        setMeasurementFormData(allMeasurements);
        
    }, [latestMeasurements]);
    
    // Zapisywanie wartości pól pomiarowych

    function measurementFormChange(event){
        
        const {name, value} = event.target;

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
        
    }

    // Przyciski zmniejszania/zwiększania ilości

    function changeMeasurementFormValue(rawName, type){
        let name = rawName[0];
        let value = parseInt(measurementFormData[name]);
        if(type === 'decrement'){
            if(!value || value === 0){
                setMeasurementFormData({...measurementFormData, [name]: '0'});
            } else {
                setMeasurementFormData({...measurementFormData, [name]: (value -1).toString()});
            }
        } else {
            if(!value){
                setMeasurementFormData({...measurementFormData, [name]: '1'});
            } else {
                setMeasurementFormData({...measurementFormData, [name]: (value +1).toString()});
            }
        }
    }

    // Sprawdzanie aktualnego pomiaru

    function acceptMeasurementForm(rawName, index){

        let err = document.querySelector(["#train-measurements-form-error-"+index]);

        let rawDateTime = new Date().toISOString().split('T');

        let currentDay =  rawDateTime[0];
        
        let rawTime =  rawDateTime[1];
    
        let currentTime = rawTime.split(".")[0];

        const measurements = {}

        // Stacje

        if(index % 2 === 0){
            let ele1 = document.querySelector(['#'+rawName+'-'+index+'-1']);
            let ele2 = document.querySelector(['#'+rawName+'-'+index+'-2']);
            let val1 = measurementFormData[rawName+'-'+index+'-1'];
            let val2 = measurementFormData[rawName+'-'+index+'-2'];
            measurements[rawName+'-'+index+'-1'] = measurementFormData[rawName+'-'+index+'-1'];
            measurements[rawName+'-'+index+'-2'] = measurementFormData[rawName+'-'+index+'-2'];
            if(!val1 || !val2){
                err.innerText = 'Proszę uzupełnij brakujące wartości';
                if(!val1){
                    ele1.classList.add('form-field-error');
                }
                if(!val2){
                    ele2.classList.add('form-field-error');
                }
            } else {
                ele1.classList.remove('form-field-error');
                ele2.classList.remove('form-field-error');
                err.innerText = '';
                setCompletedMeasurements(prev => [...prev,index]);
                setNextStation(index);
                completeMeasurements();
            }

        // Przejazdy między stacjami

        } else {
            let cars_numbers = active.last_car - active.first_car +1;
            const arr = [];
            for(let i = 0; i < cars_numbers; i++){
                window['ele-'+i] = document.querySelector(['#'+rawName+'-'+index+'-car-'+(active.first_car+i)]);
                window['val-'+i]= measurementFormData[rawName+'-'+index+'-car-'+(active.first_car+i)];
                if(!window['val-'+i]){
                    window['ele-'+i].classList.add('form-field-error');
                } else {
                    window['ele-'+i].classList.remove('form-field-error');
                }
                arr.push(window['val-'+i]);
                measurements[rawName+'-'+index+'-car-'+(active.first_car+i)] = measurementFormData[rawName+'-'+index+'-car-'+(active.first_car+i)];
            }
            if(arr.includes(undefined)){
                err.innerText = 'Proszę uzupełnij brakujące wartości';
            } else {
                err.innerText = '';
                setCompletedMeasurements(prev => [...prev,index]);
                setNextStation(index);
                completeMeasurements();
            }
            
        }

        // Zachowywanie pomiarów w lokalnej bazie danych

        function completeMeasurements(){

            const measurementsForDB = {
                personal_id: user.personal_id,
                job_date: active.job_date,
                job_number: active.job_number,
                train_id: active.train_id,
                train_number: active.train_number,
                station_index: index,
                date: currentDay,
                time: currentTime,
                gps_lat: userCoordinates.lat,
                gps_lng: userCoordinates.lng,
                first_car: active.first_car,
                last_car: active.last_car
            };

            if(index === 0 && active.status === "nierozpoczęty"){

                let obj = activeRides.find(f=>f.train_id === active.train_id);
                if(obj){
                    obj.status = "rozpoczęty";
                    measurements.status_update = 'rozpoczęty';
                }
                
            }

            const data = new FormData();

            if(measurementFormData["user_camera_photo-"+index]){
                data.append('camera_photo', measurementFormData["user_camera_photo-"+index]);
            }

            if(measurementFormData["user_included_photos-"+index]){
                measurementFormData["user_included_photos-"+index].forEach((file, index )=> data.append('user_included_photo-'+[index], file));
            }

            if(measurementFormData["user_camera_photo-"+index]){
                measurements["user_camera_photo-"+index] = measurementFormData["user_camera_photo-"+index];
            }

            if(measurementFormData["user_included_photos-"+index]){
                measurements["user_included_photos-"+index] = measurementFormData["user_included_photos-"+index];
            }

            if(measurementFormData["additional-comments-active-"+index] === true && measurementFormData[rawName+"-additional-comments-"+index]){
                measurements[rawName+"-additional-comments-"+index] = measurementFormData[rawName+"-additional-comments-"+index];
                measurements["additional-comments-active-"+index] = true;
            }

            measurements.name = rawName+"-"+index;

            measurements.index = index;

            measurementsForDB.measurements = measurements;

            db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: index}).toArray().then(function(result){
                if(result.length === 0){
                    measurementsForDB.measurement_id = 1;
                } else {
                    let last = result.length -1;
                    let last_measurement = result[last].measurement_id;
                    measurementsForDB.measurement_id = last_measurement + 1;
                }

                let json = JSON.stringify(measurementsForDB);

                data.append('data', json);

                Axios.post('classes/measurements.php', data, { timeout: 5000 }).then(function(response){
                    if(Array.isArray(response.data) && response.data[0] === true){
                        measurementsForDB.saved_in_DB = 'yes';
                    } else {
                        measurementsForDB.saved_in_DB = 'no';
                    }
                    db.measurements.put(measurementsForDB);
                }).catch((error) => {
                    console.warn(error);
                    measurementsForDB.saved_in_DB = "no";
                    db.measurements.put(measurementsForDB);
                });
        
            });

        }
        
    }

    // Zapisywanie pomiarów z lokalnej bazy danych do centralnej

    function saveMeasurements(old_data){

        const data = new FormData();

        const measurements = old_data.map(a => a.measurements);

        let i = 1;

        measurements.forEach(measurement => {
            let index = measurement.index;
            let name = measurement.name;
            if(measurement.hasOwnProperty(['user_camera_photo-'+index])){
                data.append([name+"-"+i], measurement["user_camera_photo-"+index]);
                i++;
            }
            if(measurement.hasOwnProperty(['user_included_photos-'+index])){
                measurement['user_included_photos-'+index].forEach(image => {
                    data.append([name+"-"+i], image);
                    i++;
                });
            }
        });

        let json = JSON.stringify(old_data);

        data.append('data', json);

        Axios.post('classes/measurements.php', data , { timeout: 5000 }).then(function(response){
            if(Array.isArray(response.data)){
                let indexes = response.data;
                if(indexes.length > 0){
                    db.measurements.where('index').anyOf(indexes).modify({saved_in_DB: "yes"});
                }
            } 
        }).catch((error) => {
            console.warn(error);
        });

    }

    function checkSaveInDB(){
        db.measurements.where('saved_in_DB').equals('no').toArray().then(function(result){
            if(result.length > 0){
                saveMeasurements(result);
            }
        });
    }

    useEffect(() => {
        const h = setInterval(() => {
            checkSaveInDB();
        }, 5000)
        return () => { clearInterval(h) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Przesunięcie na następną stację

    function setNextStation(index){
        let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
        let last = measurementStations.length -1;

        if(index === last){
            setActiveLayer(200);
        } else {
            let index_plus = index + 1;
            let next = document.querySelector(["#train-measurements-wrapper-"+index_plus]);
            wrapper.style.display = "none";
            next.style.display = 'block';
        }
        
    }

    // Ręczna zmiana mierzonej stacji

    function changeCurrentStation(type, index){
        let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
        let last = measurementStations.length -1;
        if(type === 'minus'){
            if(index === 0){
                return;
            } else {
                let index_minus = index - 1;
                let previous = document.querySelector(["#train-measurements-wrapper-"+index_minus]);
                wrapper.style.display = "none";
                previous.style.display = 'block';
            }
        }
        if(type === 'plus'){
            if(index === last){
                return;
            } else {
                if(completedMeasurements.includes(index)){
                    let index_plus = index + 1;
                    let next = document.querySelector(["#train-measurements-wrapper-"+index_plus]);
                    wrapper.style.display = "none";
                    next.style.display = 'block';
                } else {
                    return;
                }
            }
        }
    }

    // Przywracanie ostatniego pomiaru 

    useEffect(() => {
        if(appLayer === 300 && completedMeasurements.length > 0){
            let max = Math.max(...completedMeasurements);
            let max_total = measurementStations.length -1;
            if(max !== max_total){
                let wrapper = document.querySelector(["#train-measurements-wrapper-0"]);
                let next = document.querySelector(["#train-measurements-wrapper-"+(max+1)]);
                wrapper.style.display = "none";
                next.style.display = 'block';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLayer]);

    // Dodawanie zdjęcia z telefonu

    function handlePhoto(index, e){

        if (!e.target.files || e.target.files.length === 0) {

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: undefined,
                    ["user_camera_photo_url-"+index]: undefined
                }
            });

            return

        }

        let type_raw = e.target.files[0].type;
        let type = type_raw.slice(0, 5);

        if(type !== 'image'){

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: undefined,
                    ["user_camera_photo_url-"+index]: undefined
                }
            });

            return;

        } else {

            const objectUrl = URL.createObjectURL(e.target.files[0]);

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: e.target.files[0],
                    ["user_camera_photo_url-"+index]: objectUrl
                }
            });
        
            return () => URL.revokeObjectURL(objectUrl);

        }

    }

    function removeCameraImage(index){
        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_camera_photo-"+index]: undefined,
                ["user_camera_photo_url-"+index]: undefined
            }
        });
    }

    // Wysyłanie wielu zdjęć

    function handlePhotos(index, e){

        if (!e.target.files || e.target.files.length === 0) {
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_included_photos-"+index]: undefined
                }
            });
            return
        }

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        const chosenImages = chosenFiles.filter(file => {
            return file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif"
        });

        let imagesToPut;

        if(Array.isArray(measurementFormData["user_included_photos-"+index])){
            const previousImages = measurementFormData["user_included_photos-"+index];
            imagesToPut = previousImages.concat(chosenImages);
        } else {
            imagesToPut = chosenImages;
        }

        /*

        imagesToPut.forEach(image => {
            const objectUrl = URL.createObjectURL(image);

            image.url = objectUrl;
        
            URL.revokeObjectURL(objectUrl);
        });

        */

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_included_photos-"+index]: imagesToPut
            }
        });

    }

    function showPreviewPhoto(index, ix){

        const current = measurementFormData["user_included_photos-"+index][ix];

        const objectUrl = URL.createObjectURL(current);

        let imageElement = document.querySelector('#modal-preview-image');

        imageElement.src = objectUrl; 

        showModal();

    }

    function removeIncludedPhoto(index, ix){

        const current = measurementFormData["user_included_photos-"+index];

        const newArray = current.filter((item, index) => index !== ix);

        let imagesToPut;

        if(newArray.length > 0){
            imagesToPut = newArray;
        } else {
            imagesToPut = undefined;
        }

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_included_photos-"+index]: imagesToPut
            }
        });

    }

    // Modal z podglądem zdjęcia

    function showModal(){
        let ele = document.querySelector('#modal-1');
        ele.classList.remove('modal-not-visible');
    }

    function closeModal(){
        let ele = document.querySelector('#modal-1');
        ele.classList.add('modal-not-visible');
    }

    // Opcjonalny komentarz

    function toggleCommentBox(name, index){
        let current = measurementFormData["additional-comments-active-"+index];

        if(current === undefined || current === false){
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["additional-comments-active-"+index]: true
                }
            });
            //document.querySelector(["#train-measurements-footer-"+index]).scrollIntoView();
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["additional-comments-active-"+index]: false,
                    [name+'-additional-comments-'+index]: ''
                }
            });
        }
    }

    function setActiveLayer(num){
        setAppLayer(num);
        window.scrollTo(0, 0);
    }

    return (
        <div id="app-outer-container">
            <div id="app-inner-container">
                {(permissionsState.location !== "denied" && permissionsState.location !== "granted") && 
                    <div className="waiting-wrapper">
                        <p className="waiting-message">Proszę czekać trwa pobieranie lokalizacji</p>
                        <Gear/>
                        <p className="location-waiting-message-add">Uwaga - lokalizacja urządzenia jest niezbędna do prawidłowego funkcjonowania aplikacji.</p>
                    </div>
                }
                {permissionsState.location === "denied" && 
                    <div className="waiting-wrapper">
                        <p className="waiting-message">Nie udało się pozyskać lokalizacji</p>
                        <img
                            src={Icon1}
                            alt='icon'
                            className="error-icon"
                        />
                        <p className="location-waiting-message-add">Lokalizacja jest niezbędna do prawidłowego działania aplikacji.</p>
                        <p className="location-waiting-message-add">Upewnij się, że aplikacja ma do niej uprawnienia.</p>
                    </div>
                }
                {permissionsState.location === "granted" && 
                <>
                    {jobs.length === 0 && 
                        <div className="waiting-wrapper">
                            {searching && 
                            <>
                                <p className="waiting-message">Wyszukiwanie dostępnych obiegów</p>
                                <Gear/>
                            </>}
                            {!searching && 
                            <>
                                <p className="waiting-message">Brak dostępnych obiegów</p>
                                <button className={user.role === 'admin' ? "back-button back-button-extended" : "back-button"} onClick={()=>getData()}>Sprawdź ponownie</button>
                                {user.role === 'admin' && <button className={user.role === 'admin' ? "back-button back-button-extended" : "back-button"} onClick={()=>getData('*')}>Dostęp administracyjny</button>}
                            </>}                     
                        </div>
                    }
                    {(jobs.length > 0 && !active.job_date) && 
                        <div className="job-choose-outer-wrapper outer-wrapper">
                            <div className="job-open-header">
                                <h2 className="job-open-heading">Otwórz obieg ankieterski</h2>
                                <h3 className="job-open-sub-heading"><span>wybierz datę obiegu</span></h3>
                            </div>
                            <div className="job-list-wrapper">
                                {jobsDates.sort((a, b) => a > b ? 1 : -1).map((job, index) =>
                                    <div key={index}>
                                        <p className="job-open-name"><button className="job-open-button" onClick={()=>setActiveSelection('job_date', job)}>{job}</button></p>
                                    </div>
                                )}
                                {active.cancel && <button className="back-button" onClick={()=>cancelJobChange()}>&#8592; Anuluj</button>}
                            </div>
                        </div>
                    }
                    {(active.job_date && !active.job_number) &&
                        <div className="job-choose-outer-wrapper outer-wrapper">
                            <div className="job-open-header">
                                <h2 className="job-open-heading">Otwórz obieg ankieterski</h2>
                                <h3 className="job-open-sub-heading"><span>wybierz numer obiegu</span></h3>
                            </div>
                            <div className="job-list-wrapper">
                                {jobsNames.sort((a, b) => a > b ? 1 : -1).map((job, index) =>
                                    <div key={index}>
                                        <p className="job-open-name"><button className="job-open-button" onClick={()=>setActiveSelection('job_number', job)}>Obieg nr {job}</button></p>
                                    </div>
                                )}
                                <button className="back-button" onClick={()=>setActive({...active, job_date:''})}>&#8592; Powrót</button>
                            </div>
                        </div>
                    }
                    {(active.job_date && active.job_number) && 
                        <div id="main-outside-wrapper" className="outer-wrapper">
                            <div className="main-header-info">
                                <h2 className="job-open-heading">Aktywny obieg ankieterski</h2>
                                <h3 className="job-open-sub-heading"><span>Numer {active.job_number} na dzień {active.job_date}</span></h3>
                                <button className="change-button" onClick={()=>setActive({...active, 'cancel': true, 'job_date':'','job_number':''})}>Zmień obieg</button>
                            </div>
                            {active.train_number && 
                            <div className="main-header-info">
                                <h2 className="job-open-heading">Aktywny pociąg</h2>
                                <h3 className="job-open-sub-heading"><span>Numer {active.train_number}<br></br>{active.relation}<br></br>{active.start_hour} - {active.end_hour}</span></h3>
                                {appLayer !== 200 && <button className="change-button" onClick={()=>setActiveLayer(200)}>Zmień pociąg</button>}
                            </div>}
                        {appLayer === 100 && 
                            <>
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(200)}>
                                    <div className="main-icon-inner-wrapper">
                                        <Icon2/>
                                    </div>
                                    <p className="main-icon-description">Wybór pociągu</p>
                                </div>
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(300)}>
                                    <div className="main-icon-inner-wrapper">
                                        <Icon3/>
                                    </div>
                                    <p className="main-icon-description">Pomiary</p>
                                </div>
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(400)}>
                                    <div className="main-icon-inner-wrapper">
                                        <Icon4/>
                                    </div>
                                    <p className="main-icon-description">Szczegóły zadań</p>
                                </div>
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(500)}>
                                    <div className="main-icon-inner-wrapper">
                                        <Icon5/>
                                    </div>
                                    <p className="main-icon-description">Pomocne materiały</p>
                                </div>
                                {user.role === 'admin' && 

                                    <div className="main-icon-outer-wrapper">
                                        <Link to="../admin">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon6/>
                                        </div>
                                        <p className="main-icon-description">Panel admina</p>
                                        </Link>
                                    </div>
                                }
                                {user.role === 'admin' &&     
                                    <div className="main-icon-outer-wrapper" onClick={logout}>
                                        <div className="main-icon-inner-wrapper">
                                            <Icon7/>
                                        </div>
                                        <p className="main-icon-description">Wyloguj się</p>
                                    </div>
                                }
                            </>
                        }
                        {appLayer === 200 &&
                            <div id="train-details-tables-wrapper">
                            {activeRides.map((ride, index) =>
                                <div key={index}>
                                    <table className="train-details-table">
                                        <thead>
                                            <tr>
                                                <th>Pozycja w obiegu</th>
                                                <td>{ride.position}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Numer pociągu</th>
                                                <td>{ride.train_number}</td>
                                            </tr>
                                            <tr>
                                                <th>Relacja pociągu</th>
                                                <td>{ride.begin_station} - {ride.end_station}</td>
                                            </tr>
                                            <tr>
                                                <th>Mierzony na trasie</th>
                                                <td>{ride.first_station_name} - {ride.last_station_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Łączna ilość wagonów</th>
                                                <td>{ride.total_cars}</td>
                                            </tr>
                                            <tr>
                                                <th>Wagony do pomiaru</th>
                                                <td>{ride.first_car} - {ride.last_car}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{ride.status}</td>
                                            </tr>
                                            <tr>
                                                <th>Rozkład jazdy</th>
                                                <td><button className="train-details-show-button" id={"train-details-show-button-"+index} onClick={()=>trainDetailsToggle(index)}>Pokaż</button></td>
                                            </tr>
                                            <tr className="train-is" id={"train-details-inner-section-"+index}>
                                                <td colSpan="2">
                                                    {activeTrains.filter(train => train.train_id === ride.train_id).map((train, index) => 
                                                        <section key={index}>
                                                            <p className="train-details-inner-section-header-wrapper">
                                                                <span className="train-details-inner-section-header section-40">Stacja kolejowa</span>
                                                                <span className="train-details-inner-section-header section-20">Przyjazd</span>
                                                                <span className="train-details-inner-section-header section-20">Odjazd</span>
                                                                <span className="train-details-inner-section-header section-20">Peron<br></br>/tor</span>
                                                            </p>
                                                        {train.stations && train.stations.map((station, index) => 
                                                            <p className="train-details-inner-section-table-wrapper" key={index}>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-40 station-important" : "train-details-inner-section-table section-40"}>{station[1]}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[2] ? station[2] : '-'}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[3] ? station[3] : '-'}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[4] ? station[4] : 'b.d.'}/{station[5] ? station[5] : 'b.d.'}</span>
                                                            </p>  
                                                        )}
                                                        </section>
                                                    )}
                                                    <button className="train-details-show-button show-button-second" onClick={()=>trainDetailsToggle(index)}>Ukryj</button>            
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <button className="train-set-measurements-button" onClick={()=>activateTrain(ride.train_id)}>
                                                        <span className="train-set-measurement-svg-container"><Icon2/></span>
                                                        <span className="train-set-measurement-label">Wybierz pociąg</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                 
                                </div>
                            )}
                            </div>
                        }
                        {appLayer === 300 &&
                            <div id="measurements-layer-outer-wrapper">
                                {!active.train_number && 
                                    <div className="waiting-wrapper">                       
                                        <p className="waiting-message">Przed rozpoczęciem pomiaru należy najpierw wybrać pociąg</p>
                                        <button className="train-set-measurements-button" onClick={()=>setActiveLayer(200)}>
                                            <span className="train-set-measurement-svg-container"><Icon2/></span>
                                            <span className="train-set-measurement-label">Wybór pociągu</span>
                                        </button>    
                                    </div>
                                }
                                {active.train_number && 
                                    <div>
                                        <div id="train-measurements-header-wrapper">
                                            <h1 id="train-measurements-heading">Kierunek jazdy pociągu</h1>
                                            <p id="train-measurements-sub-heading">&#8673;</p>
                                            <img
                                                src={TrainFront}
                                                alt='train'
                                                className="train-graphic"
                                            />
                                        </div>
                                        {measurementStations.map((station, index) =>
                                            <div id={'train-measurements-outer-wrapper-'+index} key={index}>
                                                <div className="train-measurements-inner-wrapper" id={"train-measurements-wrapper-"+index}>
                                                    <div className="train-measurements-inner-header-wrapper">
                                                        <h3 className="train-measurements-inner-heading">Mierzony odcinek</h3>
                                                        <p className="train-measurements-inner-sub-heading">{index % 2 === 0 ? 'Stacja ' : 'Przejazd '}{station[1]}</p>
                                                        <div className="train-measurements-controls-wrapper">
                                                            <button className={index !== 0 ? "change-measurement-stage-button" : "change-measurement-stage-button button-hidden"} onClick={()=>changeCurrentStation('minus', index)}><span></span><span></span></button>
                                                            <button className={(index !== measurementStations.length -1 && index <= Math.max(...completedMeasurements)) ? "change-measurement-stage-button" : "change-measurement-stage-button button-hidden"} onClick={()=>changeCurrentStation('plus', index)}><span></span><span></span></button>
                                                        </div>
                                                    </div>
                                                    {(index % 2 === 0) && 
                                                        <div className="train-measurements-inner-flex-wrapper">
                                                            <div className="train-measurements-inner-container">
                                                                <div className="train-measurements-inner-left-wrapper">
                                                                    <p className="train-measurement-type-title">&nbsp;Wsiadło&nbsp;</p>
                                                                </div>
                                                                <div className="train-measurements-inner-right-wrapper">
                                                                    <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-1'], 'decrement')}>–</button>
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        onChange={measurementFormChange}
                                                                        id={[station[0]+'-'+index+'-1']}
                                                                        name={[station[0]+'-'+index+'-1']}
                                                                        value={measurementFormData[station[0]+'-'+index+'-1'] || ''}
                                                                    />
                                                                    <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-1'], 'increment')}>+</button>
                                                                </div>
                                                            </div>
                                                            <div className="train-measurements-inner-container">
                                                                <div className="train-measurements-inner-left-wrapper">
                                                                    <p className="train-measurement-type-title">Wysiadło</p>
                                                                </div>
                                                                <div className="train-measurements-inner-right-wrapper">
                                                                    <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-2'], 'decrement')}>–</button>
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        onChange={measurementFormChange}
                                                                        id={[station[0]+'-'+index+'-2']}
                                                                        name={[station[0]+'-'+index+'-2']}
                                                                        value={measurementFormData[station[0]+'-'+index+'-2'] || ''}
                                                                    />
                                                                    <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-2'], 'increment')}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!(index % 2 === 0) && 
                                                        <div key={index}>
                                                            {[...Array(active.last_car - active.first_car +1)].map(
                                                                (val, ind) =>
                                                                <div className="train-measurements-inner-flex-wrapper" key={ind}>
                                                                    <div className="train-measurements-inner-container">
                                                                        <div className="train-measurements-inner-left-wrapper">
                                                                            <span className="train-measurements-car-number">{active.first_car + ind}</span>
                                                                            <img
                                                                                src={TrainCar}
                                                                                alt='train'
                                                                                className="train-graphic"
                                                                            />
                                                                        </div>
                                                                        <div className="train-measurements-inner-right-wrapper">
                                                                            <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-car-'+(active.first_car+ind)], 'decrement')}>–</button>
                                                                            <input 
                                                                                className="input-number" 
                                                                                type="number" 
                                                                                min="0" 
                                                                                onChange={measurementFormChange}
                                                                                id={[station[0]+'-'+index+'-car-'+(active.first_car+ind)]}
                                                                                name={[station[0]+'-'+index+'-car-'+(active.first_car+ind)]}
                                                                                value={measurementFormData[station[0]+'-'+index+'-car-'+(active.first_car+ind)] || ''}
                                                                            />
                                                                            <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-car-'+(active.first_car+ind)], 'increment')}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    <div className="measurement-icon-outer-wrapper">
                                                        <div className="measurement-icon-container">
                                                            <label htmlFor={["capture-image-"+index]} className="measurement-icon-label">
                                                                <Icon8/>
                                                            </label>
                                                            <p className="measurement-icon-description">{measurementFormData["user_camera_photo-"+index] ? <span>Zastąp<br></br>zdjęcie</span> : <span>Zrób<br></br>zdjęcie</span>}</p>
                                                            <input accept="image/*" id={["capture-image-"+index]} className="capture-image" type="file" capture="environment" onChange={(e) => handlePhoto(index, e)}/>
                                                        </div>
                                                        <div className="measurement-icon-container">
                                                            <label htmlFor={["capture-images-"+index]} className="measurement-icon-label">
                                                                <Icon9/>
                                                            </label>
                                                            <p className="measurement-icon-description">Dołącz<br></br>serię zdjęć</p>
                                                            <input accept="image/*" id={["capture-images-"+index]} className="capture-image" type="file" multiple onChange={(e) => handlePhotos(index, e)}/>
                                                        </div>
                                                        <div className="measurement-icon-container">
                                                            <div className="measurement-icon-label" onClick={() => toggleCommentBox(station[0], index)}>
                                                                <Icon10/>
                                                            </div>
                                                            <p className="measurement-icon-description">{measurementFormData["additional-comments-active-"+index] ? <span>Usuń<br></br>komentarz</span> : <span>Dodaj<br></br>komentarz</span>}</p>
                                                        </div>
                                                    </div>
                                                    {measurementFormData["user_camera_photo-"+index] && 
                                                        <div className="user-image-preview-wrapper">
                                                            <div className="user-image-preview-container">
                                                                <img id={["user-image-preview-"+index]} className="image-preview" src={measurementFormData["user_camera_photo_url-"+index]} alt="user-input" /> 
                                                                <div className="user-image-remove" onClick={()=> removeCameraImage(index)}><Icon12/></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {measurementFormData["user_included_photos-"+index] && 
                                                        <table className="user-included-photos-table">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4}>Lista dołączonych zdjęć</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {measurementFormData["user_included_photos-"+index].map((photo, ix) =>
                                                                <tr key={ix}>
                                                                    <td>{ix+1}</td>
                                                                    <td>{photo.name}</td>
                                                                    <td><span className="user-included-photos-table-button" onClick={() => showPreviewPhoto(index, ix)}><Icon11/></span></td>
                                                                    <td><span className="user-included-photos-table-button" onClick={() => removeIncludedPhoto(index, ix)}><Icon12/></span></td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    }
                                                    {measurementFormData["additional-comments-active-"+index] && 
                                                        <div className="train-measurement-additional-comments-wrapper">
                                                            <p className="train-measurement-additional-comments-heading">Komentarz do pomiaru</p>
                                                            <textarea
                                                                className="train-measurement-additional-comments"
                                                                onChange={measurementFormChange}
                                                                id={[station[0]+'-additional-comments-'+index]}
                                                                name={[station[0]+'-additional-comments-'+index]}
                                                                value={measurementFormData[station[0]+'-additional-comments-'+index]}
                                                            />
                                                        </div>
                                                    }
                                                    <div id={"train-measurements-footer-"+index} className="train-measurements-footer-wrapper">
                                                        <button className={(index !== measurementStations.length -1) ? "train-measurements-form-button" : "train-measurements-form-button train-measurements-form-button-finish"} onClick={()=>acceptMeasurementForm(station[0],index)}>{index !== (measurementStations.length -1) ? 'Zapisz pomiar' : 'Zakończ pomiary'}</button>
                                                        <p className="train-measurements-form-error" id={"train-measurements-form-error-"+index}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        }
                        {appLayer !== 100 && 
                            <div id="sticky-menu-outside">
                                <div className={appLayer === 400 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(400)}>
                                    <Icon4/>
                                </div>
                                <div className={appLayer === 200 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(200)}>
                                    <Icon2/>
                                </div>
                                <div id="sticky-main-menu-icon" className="sticky-menu-icon-wrapper" onClick={()=>setActiveLayer(100)}>
                                    <Hamburger/>
                                </div>
                                <div className={appLayer === 300 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(300)}>
                                    <Icon3/>
                                </div>
                                <div className={appLayer === 500 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(500)}>
                                    <Icon5/>
                                </div>
                            </div>
                        }
                    </div>}
                </>}
            </div>
            <div id="modal-1" className="modal-outer-container modal-not-visible" onClick={()=>closeModal()}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div id="modal-1-inner-container" className="modal-inner-wrapper">
                        <img id="modal-preview-image" src='' alt="preview"></img>
                        <span id="modal-1-close-icon" className="modal-close-icon edit-user-icon" onClick={()=>closeModal(2)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                    </div>
                </div>
            </div>
        </div>
    );
};