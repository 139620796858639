import { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserContext } from './components/UserContext';
import { Common, Error, Login, Main, Admin, Panel } from './index';

export default function App() {
    
  useEffect(() => {
      document.title = 'Badania ruchu';
  }, []);

  const {user} = useContext(UserContext);

  return (        
      <>
      {user &&
      <>
      {user.role === 'none' && (
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Common />}>
                <Route index element={<Login />} />
                <Route path='*' element={<Navigate to='/' />} />
              </Route>
          </Routes>
        </BrowserRouter>
      )}
      {user.role === 'user' && (
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Common />}>
                  <Route index element={<Main />} />
                  <Route path='*' element={<Error />} />
              </Route>
          </Routes>
        </BrowserRouter>
      )}
      {user.role === 'admin' && (
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Common />}>
                  <Route index element={<Panel />} />
                  <Route path='/main' element={<Main />} />
                  <Route path='/admin' element={<Admin />} />
                  <Route path='*' element={<Error />} />
              </Route>
          </Routes>
        </BrowserRouter>
      )}
      </>
      }
      </>
  );
}
