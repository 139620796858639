import { Dexie } from 'dexie';

export const db = new Dexie('rubika');

db.version(1).stores({
    user: '++index',
    selected: '++index',
    trains: '++index, train_number',
    train_numbers: '++index, train_number',
    jobs: '++index, job_number',
    rides: '++index, job_number',
    stations: '++index, station_id',
    measurements: '++index, [job_date+job_number+train_id], [job_date+job_number+train_id+station_index], saved_in_DB',
});

db.open().catch(function (err) {
    console.error (err.stack || err);
});